import { Badge, Table } from 'react-bootstrap';
import {
  Button,
  ClickAwayListener,
  DialogTitle,
  Grid,
  MuiThemeProvider,
  Paper,
  TextField,
  Tooltip,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import {
  formatNumber,
  getBadgeColor,
  getNested,
  getPOStatusName,
} from '../../../../utils';
import {
  getAdvanceDetail,
  sendEmailLinkAdvance,
  updateAdvance,
} from '../../advancesSlice';
import {
  makeSelectAdvanceDetails,
  selectAdvances,
} from 'app/features/advances/selectors';

import CancelIcon from '@material-ui/icons/Cancel';
import MailIcon from '@material-ui/icons/Mail';
import Modal from '../../../../components/Modal';
import Products from 'app/components/Products';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';
import SmsIcon from '@material-ui/icons/Sms';
import SplashScreen from '../../../../partials/layout/SplashScreen';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { closeModals } from '../../../modals/modalsSlice';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { handleUrlLanding } from '../../../../config/landing';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import { makeSelectPaymentOrderTokenFromState } from 'app/features/tokenize/selectors';
import { makeSelectPaymentOrdersLogs } from '../../../logs/selectors';
import moment from 'moment';
import { sendNotification } from 'app/features/notifications/notificationsSlice';
import { tokenizePaymentOrder } from 'app/features/tokenize/tokenizeSlice';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
}));

function DetailPaymentOrder(props) {
  const classes = useStyles();
  const {
    closeModals,
    sendEmailLinkAdvance,
    notifications,
    advances,
    advanceDetail,
    paymentOrdersLogs,
    getAdvanceDetail,
    updateAdvance,
    SendNotification,
    paymentOrderToken,
    tokenizePaymentOrder,
    setLeftSideComponent,
    setActionButton,
    match,
    location,
  } = props;
  const [orderData, setOrder] = React.useState({});
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [showSMSModal, setShowSMSModal] = React.useState(false);
  const [
    showModalCancelingOrder,
    setShowModalCancelingOrder,
  ] = React.useState(false);
  const [
    showEmailPaymentLink,
    setShowEmailPaymentLink,
  ] = React.useState(false);
  const [showWhatsModal, setShowWhatsModal] = React.useState(false);
  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      type: '',
      date: null,
      amount: '',
      reference: '',
    },
  });

  useEffect(() => {
    setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">Detalle anticipo</h3>
        </div>
      </div>,
    );
    let data = {
      orderID: match.params.id,
      agencyID: match.params.agencyId,
    };
    getAdvanceDetail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleDisplayRightButtons(advanceDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceDetail]);

  useEffect(() => {
    if (advanceDetail) {
      const orderData = advanceDetail || {};
      const agencyData = orderData.agency || {};
      const operationsData = orderData.products || {};

      const cleanOrder = {
        id: orderData.id || 'n/a',
        status: getPOStatusName(orderData.status) || 'n/a',
        total: orderData.total ? orderData.total : 'n/a',
        orderID: orderData.orderID || 'n/a',
        reference: orderData.reference || 'n/a',
        operations: operationsData || 'n/a',
        createdDate: orderData.createdAt || 'n/a',
        nickname: agencyData.nickname || 'n/a',
        clientFullName: orderData.nombre || 'n/a',
        clientEmail: orderData.email || 'n/a',
        clientPhoneNumber: orderData.phoneNumber || 'n/a',
        vin: orderData.vin || 'n/a',
        clientRFC: orderData.rfc || 'n/a',
        type: getNested(null, orderData, 'type'),
        clientAccount: getNested(null, orderData, 'clientAccount'),
      };
      setOrder(cleanOrder);
      tokenizePaymentOrder({
        reference: getNested(null, orderData, 'reference'),
        agencyID: getNested(null, orderData, 'agencyID'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceDetail]);

  const handleDisplayRightButtons = (orderDetails) => {
    const status = getNested(null, advanceDetail, 'status');
    if (orderDetails && status === 'por pagar') {
      return setActionButton(
        <>
          <ShouldItRender
            should={Boolean(orderDetails.agency.smsUnitNotifications)}
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="shareLink"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleSMSModal}
            >
              SMS
              <SmsIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.whatsUnitNotifications,
            )}
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="shareLink"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleWhatsModal}
            >
              WhatsApp
              <WhatsAppIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.emailUnitNotifications,
            )}
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="shareLink"
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => setShowEmailPaymentLink(true)}
            >
              Correo
              <MailIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            locationPage={`/${location.pathname.split('/')[1]}`}
            action="cancelAdvance"
          >
            <Button
              variant="contained"
              className="btn btn-label-warning btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleCancelOrderModal}
            >
              Cancelar anticipo
              <CancelIcon className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
        </>,
      );
    } else {
      setActionButton(null);
    }
  };

  const toogleSMSModal = () => setShowSMSModal(!showSMSModal);

  const closeModalSMS = () => setShowSMSModal(false);

  const toogleWhatsModal = () => setShowWhatsModal(!showWhatsModal);

  const closeModalWhats = () => setShowWhatsModal(false);

  const toogleCancelOrderModal = () =>
    setShowModalCancelingOrder(!showModalCancelingOrder);

  const closeModalCancelingOrder = () =>
    setShowModalCancelingOrder(false);

  const handleToogleEmailLinkModal = () => {
    setShowEmailPaymentLink(!showEmailPaymentLink);
  };

  const getOrderCreator = () => {
    let creator = '';
    const tempPaymentOrderLogs = getNested([], paymentOrdersLogs);
    for (let i = 0; i < tempPaymentOrderLogs.length; i++) {
      if (tempPaymentOrderLogs[i].type === 'CREATE') {
        creator = tempPaymentOrderLogs[i].text.slice(
          tempPaymentOrderLogs[i].text.indexOf('usuario') +
          'usuario'.length +
          1,
          tempPaymentOrderLogs[i].text.length,
        );
        break;
      }
    }
    return creator;
  };

  const handleCancelOrder = () => {
    const { id } = orderData;
    const data = {
      id,
      status: 'cancelado',
    };
    updateAdvance(data);
  };

  const onSubmit = (type, formData) => {
    const { id } = match.params;
    const data = {
      isOdp: true,
      phoneNumber: formData.cellphone,
      id,
      type,
    };
    SendNotification(data);
    toogleSMSModal();
    closeModals();
  };

  const handleSendPaymentLink = (formData) => {
    const data = {
      paymentOrderID: orderData.id,
      email: formData.email,
      paymentID: match.params.paymentID,
    };
    sendEmailLinkAdvance(data, handleToogleEmailLinkModal);
    handleToogleEmailLinkModal();
  };

  const handleCloseModals = () => {
    closeModalCancelingOrder();
    closeModalWhats();
    closeModalSMS();
    closeModals();
  };

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const handleCopyText = () => {
    const landingUrl = handleUrlLanding();
    setShowTooltip(true);
    copy(`${landingUrl}odp/${paymentOrderToken}`);
  };

  const renderSendSmsDialog = () => {
    return (
      <Modal
        open={showSMSModal}
        closeModal={toogleSMSModal}
        dialogTitle="Enviar SMS"
        dialogText="Se enviará un SMS al número registrado previamente. En
        caso de ser incorrecto puede cambirlo en seguida."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="sms-form"
            className={classes.container}
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit((data) => onSubmit('sms', data))}
          >
            <TextField
              required
              name="cellphone"
              error={Boolean(errors.cellphone)}
              inputRef={register({
                required: true,
              })}
              label="Teléfono celular"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
            />
          </form>
        }
        form={'sms-form'}
      />
    );
  };

  const renderSendWhatsDialog = () => {
    return (
      <Modal
        open={showWhatsModal}
        closeModal={toogleWhatsModal}
        dialogTitle="Enviar mensaje por Whatsapp"
        dialogText="Se enviará un mensaje por medio de Whatsapp al número que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="whatsapp-form"
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) =>
              onSubmit('whatsapp', data),
            )}
          >
            <TextField
              name="cellphone"
              label="Teléfono"
              error={Boolean(errors.cellphone)}
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'number',
              }}
            />
          </form>
        }
        form={'whatsapp-form'}
      />
    );
  };

  const renderSendMailDialog = () => {
    return (
      <Modal
        open={showEmailPaymentLink}
        closeModal={handleToogleEmailLinkModal}
        dialogTitle="Enviar correo"
        dialogText="Escriba el correo al cual le desea enviar el link de pago."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSendPaymentLink)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  const renderCancelOrderDialog = () => {
    return (
      <Modal
        open={showModalCancelingOrder}
        closeModal={toogleCancelOrderModal}
        dialogTitle="Cancelar anticipo"
        dialogText="Estás a punto de cancelar este anticipo, una vez
        cancelado no se puede deshacer la acción, ¿deseas
        continuar?"
        actionButtonText="Cancelar anticipo"
        onClick={handleCancelOrder}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = advances;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
            ? 'errorModal'
            : null;
        const { show, message } = advances[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderFeedBackModalnotifications = () => {
    try {
      const { successModal, errorModal } = notifications;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
            ? 'errorModal'
            : null;
        const { show, message } = notifications[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderOrderDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>ID DE LA ORDEN</td>
            <td>{orderData.reference}</td>
          </tr>
          {orderData.clientAccount && (
            <tr>
              <td className={classes.tableTitle}>ID DE CLIENTE</td>
              <td>{orderData.clientAccount}</td>
            </tr>
          )}
          <tr>
            <td className={classes.tableTitle}>ESTADO</td>
            <td>
              <Badge
                className={classes.badges}
                variant={getBadgeColor(orderData.status)}
              >
                {orderData.status}
              </Badge>
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>CREADA POR</td>
            <td>{getOrderCreator()}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>MONTO ORIGINAL</td>
            <td>{formatNumber(orderData.total)}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>FECHA REGISTRO EN KARLO</td>
            <td>
              {moment(orderData.createdDate).format(
                'DD/MM/YYYY hh:mm a',
              )}
            </td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>ID KARLO</td>
            <td>{orderData.id}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderUserDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>
              NOMBRE O RAZÓN SOCIAL
            </td>
            <td>{orderData.clientFullName}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>CORREO</td>
            <td>{orderData.clientEmail}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>TELÉFONO CELULAR</td>
            <td>{orderData.clientPhoneNumber}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>VIN</td>
            <td>{orderData.vin}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderUrlDetails = () => {
    const landingUrl = handleUrlLanding();
    return (
      <>
        <ClickAwayListener onClickAway={handleHideTooltip}>
          <div>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                className="copied-url-tooltip"
                placement="top"
                onClose={handleHideTooltip}
                open={showTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="¡URL copiada al portapapeles!"
              >
                <Table
                  bordered
                  className={classes.tables}
                  onClick={handleCopyText}
                >
                  <tbody>
                    <tr>
                      <td className={classes.tableTitle}>URL</td>
                      <td>
                        {`${landingUrl}odp/${paymentOrderToken.substring(
                          0,
                          30,
                        )}...`}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Tooltip>
            </MuiThemeProvider>
          </div>
        </ClickAwayListener>
      </>
    );
  };

  return isEmpty(orderData) ? (
    <SplashScreen></SplashScreen>
  ) : (
    <div>
      {renderSendSmsDialog()}
      {renderSendWhatsDialog()}
      {renderSendMailDialog()}
      {renderCancelOrderDialog()}
      {renderFeedBackModal()}
      {renderFeedBackModalnotifications()}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Paper className={classes.paper}>
            <DialogTitle className={classes.title}>
              Resumen de la orden
            </DialogTitle>
            {renderOrderDetails()}
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          <Paper className={classes.paperUrl}>
            <DialogTitle className={classes.title}>
              URL DE PAGO
            </DialogTitle>
            {renderUrlDetails()}
          </Paper>
          <Paper className={classes.paperCliente}>
            <DialogTitle className={classes.title}>
              Cliente
            </DialogTitle>
            {renderUserDetails()}
          </Paper>
          <Products
            title="Detalles del anticipo"
            products={orderData.operations}
          />
          {Array.isArray(paymentOrdersLogs) &&
            paymentOrdersLogs.length > 0 && (
              <Grid item>
                <RecentActivities logs={paymentOrdersLogs} />
              </Grid>
            )}
        </Grid>
      </Grid>
    </div>
  );
}

const mapStateToProps = (state) => ({
  advances: selectAdvances(state),
  advanceDetail: makeSelectAdvanceDetails(state),
  notifications: state.notifications,
  paymentOrdersLogs: makeSelectPaymentOrdersLogs(state),
  agency: state.agencies.agencyDetails,
  paymentOrderToken: makeSelectPaymentOrderTokenFromState(state),
});

const mapDispatchToProps = {
  closeModals,
  SendNotification: sendNotification,
  getAdvanceDetail: getAdvanceDetail,
  updateAdvance: updateAdvance,
  sendEmailLinkAdvance: sendEmailLinkAdvance,
  tokenizePaymentOrder,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailPaymentOrder),
  ),
);
