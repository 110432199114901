import React, { useEffect, useState } from 'react';

import { Badge, Table, Alert } from 'react-bootstrap';

import {
  Button,
  ClickAwayListener,
  DialogTitle,
  Grid,
  MuiThemeProvider,
  Paper,
  TextField,
  Tooltip,
  Typography,
  createMuiTheme,
  makeStyles,
  Select,
  InputLabel,
  FormControl,
} from '@material-ui/core';

import {
  CallMade,
  Description,
  LocalOffer,
  Mail,
  Update,
  WhatsApp,
  GetApp,
  Sms,
} from '@material-ui/icons';

import Modal from '../../../../components/Modal';
import Products from 'app/components/Products';
import { PromotionMonthsCard } from 'app/components/PromotionMonthsCard';
import RecentActivities from '../../../../components/RecentActivities';
import ShouldItRender from '../../../../components/ShouldItRender';

import {
  formatNumber,
  getBadgeColor,
  getNested,
  getSOStatusName,
  mappedAssesors,
} from '../../../../utils';

import {
  createOrUpdateSurtaxes,
  getOrderDetail,
  invoiceOrder,
  sendEmailLinkServiceOrder,
  setReadyToPayServiceOrder,
  updateServiceOrder,
  downloadfileXml,
} from 'app/features/serviceOrders/serviceOrdersSlice';
import { closeModals } from 'app/features/modals/modalsSlice';
import { sendNotification } from 'app/features/notifications/notificationsSlice';
import { tokenizeServiceOrder } from 'app/features/tokenize/tokenizeSlice';

import { makeSelectServiceOrderDetails } from 'app/features/serviceOrders/selectors';
import { makeSelectServiceOrderTokenFromState } from 'app/features/tokenize/selectors';
import { makeSelectServiceOrdersLogs } from '../../../logs/selectors';
import { makeSelectUserRoleFromState } from 'app/features/auth/selectors';

import SplashScreen from '../../../../partials/layout/SplashScreen';
import { agenciesMercedes } from '../../../../config/customizing';
import { handleUrlLanding } from '../../../../config/landing';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import { injectIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
        color: 'lightblue',
        backgroundColor: 'green',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  paperTarjeta: {
    textAlign: 'left',
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
  },
  title: {
    textAlign: 'left',
    color: '#1c1c1c',
  },
  content: {
    textAlign: 'left',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1, 1, 1, 1),
  },
  menu: {
    width: 200,
  },
  margin: {
    margin: theme.spacing(1),
  },
  list: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  inline: {
    display: 'inline',
  },
  bigContainer: {
    display: 'flex',
  },
  leftContainer: {
    flexDirection: 'column',
  },
  rightContainer: {
    flexDirection: 'column',
  },
  item: {
    textAlign: 'left',
    justifyContent: 'space-between',
  },
  paperOrden: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperCliente: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperOps: {
    width: '100%',
    margin: theme.spacing(2, 0),
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  paperUrl: {
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.text.secondary,
  },
  itemBlock: {
    display: 'flex',
  },
  item1: {
    textAlign: 'left',
  },
  item2: {
    textAlign: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  tableTitle: {
    fontWeight: 401,
  },
  tables: {
    width: '100%',
  },
  media: {
    height: 60,
    width: '100%',
    position: 'relative',
  },
  feedBackStatus: {
    width: '50px',
    height: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '30px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  inputs: {
    marginTop: theme.spacing(1),
  },
  select: {
    margin: theme.spacing(1, 0, 1, 0),
  },
  linkBtn: {
    color: '#0abb87',
    '&:hover': {
      color: '#ffffff',
    },
  },
}));

const DetailOrder = (props) => {
  const classes = useStyles();
  const {
    closeModals,
    sendEmailLinkServiceOrder,
    notifications,
    serviceOrders,
    serviceOrderDetails,
    serviceOrdersLogs,
    updateServiceOrder,
    setReadyToPayServiceOrder,
    createOrUpdateSurtaxes,
    serviceOrderToken,
    tokenizeServiceOrder,
    role,
    downloadfileXml,
  } = props;
  const [orderData, setOrder] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [
    showEmailPaymentLink,
    setShowEmailPaymentLink,
  ] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [
    showPromotionsModal,
    setShowPromotionsModal,
  ] = useState(false);
  const [showWhatsModal, setShowWhatsModal] = useState(false);
  const [showReadyToPay, setShowReadyToPay] = useState(false);
  const [showNotifyOrder, setShowNotifyOrder] = useState(false);
  const [showInvoiceOrder, setShowInvoiceOrder] = useState(
    false,
  );
  const [balance, setBalance] = useState(0);
  const [paymentsMiniCard, setPaymentsMiniCard] = useState([]);
  const [paymentsToRender, setPaymentsToRender] = useState([]);
  const [invoicePDF, setInvoicePDF] = useState('');
  const [invoiceXML, setInvoiceXML] = useState('');
  const [monthsChecked, setMonthsChecked] = useState({
    amex: {
      3: 'false',
      6: 'false',
      9: 'false',
      12: 'false',
      18: 'false',
    },
    visa: {
      3: 'false',
      6: 'false',
      9: 'false',
      12: 'false',
      18: 'false',
    },
  });
  const [lada, setLada] = useState('+52');

  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      amount: '',
      clientAccount: '',
    },
  });

  useEffect(() => {
    props.setLeftSideComponent(
      <div className="kt-subheader__main">
        <div>
          <h3 className="kt-subheader__title">
            Detalle orden de servicio
          </h3>
        </div>
      </div>,
    );
    let data = {
      karloID: props.match.params.id,
      agencyID: props.match.params.agencyId,
    };
    props.getOrderDetail(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { orderDetails } = serviceOrders;
    if (orderDetails) {
      setInvoicePDF(orderDetails.urlFile);
      setInvoiceXML(`${orderDetails.urlFileXml}`);
      handleDisplayRightButtons(orderDetails);
      handlerenderPaymentsDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrders, serviceOrderDetails, orderData]);

  useEffect(() => {
    if (serviceOrderDetails) {
      const orderData = serviceOrderDetails || {};
      const userData = orderData.invoiceProfile || {};
      const agencyData = orderData.agency || {};
      const operationsData = orderData.operations || {};
      const addressData = userData.address || {};
      const vehicleData = orderData.vehicle || {};
      const paymentData = getNested(null, orderData, 'payments')[0];
      const detailPromotionByAgency = getNested(
        null,
        orderData,
        'detailPromotionByAgency',
      );

      const cleanOrder = {
        id: orderData.id || 'n/a',
        asesorName: orderData.asesor || 'n/a',
        detailPromotionByAgency,
        status: orderData.status
          ? getSOStatusName(
            orderData.status,
            agencyData &&
            'id' in agencyData &&
            agenciesMercedes.includes(agencyData.id),
          )
          : 'n/a',
        vin: orderData.vin || 'n/a',
        subtotal: orderData.subtotal || 'n/a',
        total: orderData.total || 'n/a',
        orderID: orderData.orderID || 'n/a',
        operations: operationsData || 'n/a',
        userName: userData.fullName || 'n/a',
        userPhoneNumber: userData.phoneNumbers || 'n/a',
        userEmail: userData.email || 'n/a',
        ext: addressData.ext || 'n/a',
        street: addressData.street || 'n/a',
        colony: addressData.colony || 'n/a',
        city: addressData.city || 'n/a',
        state: addressData.state || 'n/a',
        postalCode: addressData.postalCode || 'n/a',
        brand: vehicleData.brand || 'n/a',
        model: vehicleData.model || 'n/a',
        version: vehicleData.version || 'n/a',
        year: vehicleData.year || 'n/a',
        plates: vehicleData.plates || 'n/a',
        createdDate: orderData.createdDate || 'n/a',
        nickname: agencyData.nickname || 'n/a',
        orderPrice: (paymentData && paymentData.total) || 'n/a',
        payments: paymentData,
        urlPayment: orderData.urlPayment,
        advisorName: getNested('-', orderData, 'adviser'),
        advisorPhone: getNested('-', orderData, 'adviserPhoneNumber'),
        advisorEmail: getNested('-', orderData, 'adviserEmail'),
        createdAt: orderData.createdAt || 'n/a',
      };
      setOrder(cleanOrder);
      tokenizeServiceOrder({
        orderID: getNested(undefined, orderData, 'orderID'),
        agencyID: getNested(undefined, orderData, 'agencyID'),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrderDetails]);

  useEffect(() => {
    const tempChecked = { ...monthsChecked };
    setMonthsChecked(tempChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceOrderDetails]);

  const handleDisplayRightButtons = (orderDetails) => {
    if (
      orderDetails &&
      orderDetails.status &&
      orderDetails.status === 'remission'
    ) {
      return props.setActionButton(
        <>
          <ShouldItRender
            should={
              orderDetails.agency.odsServicePromotions ||
              role === 'Agency Admin' ||
              role === 'Super Admin' ||
              role === 'Super Admin Agencies' ||
              role === 'W32 Agency Admin' ||
              role === 'Service Manager' ||
              role === 'Agency Manager' ||
              role === 'Super Admin WTF'
            }
            action="addPromotions"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => setShowPromotionsModal(true)}
            >
              Promociones
              <LocalOffer className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={
              Boolean(orderDetails.agency.smsUnitNotifications) &&
              role !== 'Super Admin Agencies'
            }
            action="shareLink"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleSMSModal}
            >
              Enviar SMS
              <Sms className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.whatsUnitNotifications,
            )}
            action="shareLink"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={toogleWhatsModal}
            >
              Enviar WhatsApp
              <WhatsApp className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
          <ShouldItRender
            should={Boolean(
              orderDetails.agency.emailUnitNotifications,
            )}
            action="shareLink"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => setShowEmailPaymentLink(true)}
            >
              Enviar correo
              <Mail className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
        </>,
      );
    } else if (
      orderDetails &&
      orderDetails.status &&
      orderDetails.status === 'open'
    ) {
      return props.setActionButton(
        <>
          <ShouldItRender
            should={
              (orderDetails.agency.DMS === 'ftp-autoline' ||
                orderDetails.agencyID === 46 ||
                orderDetails.agency.DMS === 'gds-vw-lindavista' ||
                orderDetails.agencyID === 64) &&
              orderDetails.status === 'open'
            }
            action="orderToPay"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-warning btn-bold btn-sm"
              onClick={showReadyToPayModal}
            >
              Cerrar orden
              <Update className="kt-margin-l-10" />
            </Button>
          </ShouldItRender>
        </>,
      );
    } else if (
      orderDetails &&
      orderDetails.status &&
      orderDetails.status === 'payment' ||
      orderDetails.status === 'billed'
    ) {
      return props.setActionButton(
        <>
          <ShouldItRender
            should={role === 'Super Admin'}
            action="orderToPay"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            <Button
              variant="contained"
              className="btn btn-label-info btn-bold btn-sm"
              onClick={showInvoiceOrderModal}
            >
              Facturar orden
              <Description className="kt-margin-l-10" />
            </Button>
            {orderDetails.status === 'payment' && (
              <Button
                variant="contained"
                className="btn btn-label-warning btn-bold btn-sm kt-margin-l-10"
                onClick={showNotifyOrderModal}
              >
                Notificar orden
                <CallMade className="kt-margin-l-10" />
              </Button>
            )}

          </ShouldItRender>
          {
            (role === 'Super Admin' ||
              role === 'Agency Admin' ||
              role === 'Super Admin' ||
              role === 'Super Admin Agencies' ||
              role === 'W32 Agency Admin' ||
              role === 'Service Manager' ||
              role === 'Agency Manager' ||
              role === 'Super Admin WTF' ||
              role === 'Cashier' ||
              role === 'Accounting'
            ) &&
            orderDetails.urlFile &&
            orderDetails.urlFile !== null &&
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
            >
              <a
                className={classes.linkBtn}
                href={invoicePDF}
                target="_blank"
                rel="noopener noreferrer"
              >
                Descargar factura
              </a>
              <GetApp className="kt-margin-l-10" />
            </Button>
          }
          {
            (role === 'Super Admin' ||
              role === 'Agency Admin' ||
              role === 'Super Admin' ||
              role === 'Super Admin Agencies' ||
              role === 'W32 Agency Admin' ||
              role === 'Service Manager' ||
              role === 'Agency Manager' ||
              role === 'Super Admin WTF' ||
              role === 'Cashier' ||
              role === 'Accounting'
            ) &&
            orderDetails.urlFileXml &&
            orderDetails.urlFileXml !== null &&
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              onClick={() => downloadfileXml({ serviceOrderID: orderDetails.id })}
            >
              DESCARGAR XML
              <GetApp className="kt-margin-l-10" />
            </Button>
          }
        </>,
      );
    } else if (
      orderDetails &&
      orderDetails.status &&
      orderDetails.status === 'billed'
    ) {
      return props.setActionButton(
        <>
          <ShouldItRender
            should={true}
            action="orderToPay"
            locationPage={`/${props.location.pathname.split('/')[1]}`}
            compounded
          >
            {
              orderDetails.urlFile &&
              orderDetails.urlFile !== null &&
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
              >
                <a
                  className={classes.linkBtn}
                  href={invoicePDF}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Descargar factura
                </a>
                <GetApp className="kt-margin-l-10" />
              </Button>
            }
            {
              orderDetails.urlFileXml &&
              orderDetails.urlFileXml !== null &&
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-l-10"
                onClick={() => downloadfileXml({ serviceOrderID: orderDetails.id })}
              >
                DESCARGAR XML
                <GetApp className="kt-margin-l-10" />
              </Button>
            }
          </ShouldItRender>
        </>,
      );
    } else {
      props.setActionButton(null);
    }
  };

  const showInvoiceOrderModal = () =>
    setShowInvoiceOrder(!showInvoiceOrder);

  const showNotifyOrderModal = () =>
    setShowNotifyOrder(!showNotifyOrder);
  const showReadyToPayModal = () => setShowReadyToPay(true);
  const hideReadyToPayModal = () => setShowReadyToPay(false);

  const toogleWhatsModal = () => setShowWhatsModal(!showWhatsModal);

  const toogleSMSModal = () => setShowSMSModal(!showSMSModal);

  const handleToogleEmailLinkModal = () => {
    setShowEmailPaymentLink(!showEmailPaymentLink);
  };

  const handleUpdateOrder = () => {
    const data = {
      karloID: getNested(null, serviceOrders, 'orderDetails', 'id'),
      orderID: getNested(
        null,
        serviceOrders,
        'orderDetails',
        'orderID',
      ),
      agencyID: getNested(
        null,
        serviceOrders,
        'orderDetails',
        'agencyID',
      ),
    };
    updateServiceOrder(data);
  };

  const onSubmit = async (type, formData) => {
    const data = {
      isOdp: false,
      phoneNumber: formData.cellphone,
      email: formData.email,
      id: orderData.id,
      type,
      lada,
    };
    await props.SendNotification(data);
    if (type === 'sms') toogleSMSModal();
    else if (type === 'whatsapp') toogleWhatsModal();
    else if (type === 'notify') showNotifyOrderModal();
  };

  const onSubmitInvoice = async (formData) => {
    const data = {
      id: orderData.id,
    };

    props.invoiceOrder(data);
    setShowInvoiceOrder(false);
  };

  const handleSendPaymentLink = (formData) => {
    const data = {
      serviceOrderID: orderData.id,
      email: formData.email,
    };
    sendEmailLinkServiceOrder(data, handleToogleEmailLinkModal);
    handleToogleEmailLinkModal();
  };

  const handleSetOrderReadyToPay = () => {
    const data = {
      orderID: orderData.orderID,
      karloID: getNested(null, serviceOrders, 'orderDetails', 'id'),
      agencyID: getNested(
        null,
        serviceOrders,
        'orderDetails',
        'agencyID',
      ),
    };
    setReadyToPayServiceOrder(data);
    hideReadyToPayModal();
  };

  function handleCloseModals() {
    closeModals();
  }

  const handleHideTooltip = () => {
    setShowTooltip(false);
  };

  const handleCopyText = () => {
    const landingUrl = handleUrlLanding();
    setShowTooltip(true);
    if (orderData.urlPayment) {
      copy(orderData.urlPayment);
    } else {
      copy(`${landingUrl}ods/${serviceOrderToken}`);
    }
  };

  const renderSendSmsDialog = () => {
    return (
      <Modal
        open={showSMSModal}
        closeModal={toogleSMSModal}
        dialogTitle="Enviar SMS"
        dialogText="Se enviará un SMS al número registrado previamente. En
        caso de ser incorrecto puede cambirlo en seguida."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="sms-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) => onSubmit('sms', data))}
          >
            <Grid container>
              <Grid item xs={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-lada">
                    Lada
                  </InputLabel>
                  <Select
                    native
                    label="Lada"
                    onChange={(event) => setLada(event.target.value)}
                    inputProps={{
                      id: 'outlined-lada',
                    }}
                  >
                    <option value={'+52'}>+52</option>
                    <option value={'+1'}>+1</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="cellphone"
                  label="Teléfono"
                  error={Boolean(errors.cellphone)}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  variant="outlined"
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        form={'sms-form'}
      />
    );
  };

  const renderSendWhatsDialog = () => {
    return (
      <Modal
        open={showWhatsModal}
        closeModal={toogleWhatsModal}
        dialogTitle="Enviar mensaje por Whatsapp"
        dialogText="Se enviará un mensaje por medio de Whatsapp al número que ingrese."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            id="whatsapp-form"
            className={classes.container}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) =>
              onSubmit('whatsapp', data),
            )}
          >
            <Grid container>
              <Grid item xs={3}>
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-lada">
                    Lada
                  </InputLabel>
                  <Select
                    native
                    label="Lada"
                    onChange={(event) => setLada(event.target.value)}
                    inputProps={{
                      id: 'outlined-lada',
                    }}
                  >
                    <option value={'+52'}>+52</option>
                    <option value={'+1'}>+1</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="outlined-helperText"
                  name="cellphone"
                  label="Teléfono"
                  error={Boolean(errors.cellphone)}
                  fullWidth
                  inputRef={register({
                    required: true,
                  })}
                  variant="outlined"
                  inputProps={{
                    type: 'number',
                  }}
                />
              </Grid>
            </Grid>
          </form>
        }
        form={'whatsapp-form'}
      />
    );
  };

  const renderSendMailDialog = () => {
    return (
      <Modal
        open={showEmailPaymentLink}
        closeModal={() => setShowEmailPaymentLink(false)}
        dialogTitle="Enviar correo"
        dialogText="Escriba el correo al cual le desea enviar el link de pago."
        actionButtonText="Enviar"
        dialogChildren={
          <form
            className={classes.container}
            id="email-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(handleSendPaymentLink)}
          >
            <TextField
              name="email"
              label="Correo"
              className={classes.textField}
              error={errors.email && formState.touched.email}
              inputRef={register({
                required: true,
                pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
              margin="normal"
              variant="outlined"
            />
          </form>
        }
        form={'email-form'}
      />
    );
  };

  const renderReadyToPayDialog = () => {
    return (
      <Modal
        open={showReadyToPay}
        closeModal={hideReadyToPayModal}
        dialogTitle="Orden lista para pagar"
        dialogText="¿Estas seguro de que deseas cerrar la orden? Se le notificara al cliente que su vehículo ya esta listo para pagar."
        actionButtonText="Enviar"
        dialogChildren={
          <div className="text-center">
            <Typography variant="subtitle1">
              total:&nbsp;
              {formatNumber(getNested('0', orderData, 'total'))}
            </Typography>
          </div>
        }
        onClick={handleSetOrderReadyToPay}
      />
    );
  };

  const renderNotifyOrderDialog = () => {
    return (
      <Modal
        open={showNotifyOrder}
        closeModal={showNotifyOrderModal}
        dialogTitle="Notificar ODS."
        dialogText="Introduce el correo al que deseas notificar."
        actionButtonText="Notificar"
        dialogChildren={
          <form
            className={classes.container}
            id="notify-ods-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) =>
              onSubmit('notify', data),
            )}
          >
            <TextField
              name="email"
              label="Email"
              className={classes.textField}
              inputRef={register({
                required: true,
              })}
              margin="normal"
              variant="outlined"
              inputProps={{
                type: 'email',
              }}
            />
          </form>
        }
        form={'notify-ods-form'}
      />
    );
  };

  const renderInvoiceOrderDialog = () => {
    return (
      <Modal
        open={showInvoiceOrder}
        closeModal={showInvoiceOrderModal}
        dialogTitle="Facturar ODS."
        dialogText="Da clic en el boton de facturar para solicitar la factura."
        actionButtonText="Facturar"
        dialogChildren={
          <form
            className={classes.container}
            id="invoice-ods-form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit((data) => onSubmitInvoice(data))}
          ></form>
        }
        form={'invoice-ods-form'}
      />
    );
  };

  const handleUpdateSurTaxes = () => {
    const data = {
      visa: monthsChecked.visa,
      amex: monthsChecked.amex,
      serviceOrderID: getNested(null, serviceOrderDetails, 'id'),
    };
    createOrUpdateSurtaxes(data);
    setShowPromotionsModal(false);
  };

  const renderPromotionsDialog = () => {
    return (
      <Modal
        open={showPromotionsModal}
        closeModal={() => setShowPromotionsModal(false)}
        maxWidth="md"
        fullWidth
        dialogTitle="Administrar promociones"
        actionButtonText="Actualizar promociones"
        onClick={handleUpdateSurTaxes}
        dialogChildren={
          <>
            <PromotionMonthsCard
              ODS
              serviceOrderDetails={serviceOrderDetails}
              amount={getNested(0, orderData, 'total')}
              monthsChecked={monthsChecked}
              setMonthsChecked={setMonthsChecked}
              cardComission={getNested(
                null,
                serviceOrderDetails,
                'cardComission',
              )}
              amexCardComission={getNested(
                null,
                serviceOrderDetails,
                'amexCardComission',
              )}
            />
          </>
        }
        form={'advance-form'}
      />
    );
  };

  const renderFeedBackModal = () => {
    try {
      const { successModal, errorModal } = serviceOrders;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
            ? 'errorModal'
            : null;
        const { show, message } = serviceOrders[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderFeedBackModalnotifications = () => {
    try {
      const { successModal, errorModal } = notifications;
      if (
        (successModal &&
          'show' in successModal &&
          successModal.show) ||
        (errorModal && 'show' in errorModal && errorModal.show)
      ) {
        const modalType = successModal.show
          ? 'successModal'
          : errorModal.show
            ? 'errorModal'
            : null;
        const { show, message } = notifications[modalType];
        return (
          <Modal
            open={show}
            type={modalType}
            closeModal={handleCloseModals}
            dialogTitle={message.title}
            dialogText={message.desc}
            actionButtonText="Aceptar"
            onClick={handleCloseModals}
          />
        );
      }
      return null;
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  const renderPaymentInfo = () => {
    if (
      serviceOrders &&
      serviceOrderDetails &&
      serviceOrderDetails.paymentID
    )
      return (
        <>
          <tr>
            <td className={classes.tableTitle}>MONTO TOTAL PAGADO</td>
            <td>{formatNumber(orderData.orderPrice)}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>
              COMISIÓN PAGADA POR CLIENTE
            </td>
            <td>
              {formatNumber(orderData.orderPrice - orderData.total)}
            </td>
          </tr>
        </>
      );
    return null;
  };

  const renderOrderDetails = () => {
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>
          Resumen de la orden
        </DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>DMSID</td>
              <td>{orderData.orderID}</td>
            </tr>
            {
              role === 'Super Admin' ||
                role === 'Super Admin Agencies' ?
                <>
                  <tr>
                    <td className={classes.tableTitle}>Agencia</td>
                    <td>{serviceOrderDetails && serviceOrderDetails.agency && serviceOrderDetails.agency.name ? serviceOrderDetails.agency.name : ''}</td>
                  </tr>
                </>
                : null
            }
            <tr>
              <td className={classes.tableTitle}>ESTADO</td>
              <td>
                <Badge
                  className={classes.badges}
                  variant={getBadgeColor(orderData.status)}
                >
                  {orderData.status}
                </Badge>
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>MONTO ORIGINAL</td>
              <td>{formatNumber(orderData.total)}</td>
            </tr>
            {renderPaymentInfo()}
            <tr>
              <td className={classes.tableTitle}>
                FECHA REGISTRO EN KARLO
              </td>
              <td>
                {moment(orderData.createdAt).format(
                  'DD/MM/YYYY hh:mm a',
                )}
              </td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>ID KARLO</td>
              <td>{orderData.id}</td>
            </tr>
            {(orderData.status === 'Facturada' ||
              orderData.status === 'Pagada') &&
              !orderData.payments ? (
              <tr>
                <td className={classes.tableTitle}>FORMA DE PAGO</td>
                <td>Pagado en agencia</td>
              </tr>
            ) : null}
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderAdvisorDetails = () => {
    const advisorEmail = getNested('-', orderData, 'advisorEmail');
    return (
      <Paper className={classes.paper}>
        <DialogTitle className={classes.title}>Asesor</DialogTitle>

        <Table bordered className={classes.tables}>
          <tbody>
            <tr>
              <td className={classes.tableTitle}>NOMBRE</td>
              <td>{mappedAssesors(orderData.advisorName)}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>TELÉFONO</td>
              <td>{orderData.advisorPhone}</td>
            </tr>
            <tr>
              <td className={classes.tableTitle}>CORREO</td>
              <td>
                {advisorEmail.length > 19 ? (
                  <Tooltip title={advisorEmail}>
                    <div>{advisorEmail.substring(0, 15)}...</div>
                  </Tooltip>
                ) : (
                  advisorEmail
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </Paper>
    );
  };

  const renderBalanceDetails = () => {
    const advances = getNested(
      getNested([], serviceOrderDetails, 'paymentOrders'),
      serviceOrderDetails,
      'advances',
    );
    const orderStatus = getNested(
      null,
      serviceOrderDetails,
      'status',
    );
    if (advances.length > 0)
      return (
        <Paper className={classes.paperTarjeta}>
          <DialogTitle className={classes.title}>Balance</DialogTitle>
          <Table bordered className={classes.tables}>
            <tbody>
              <tr>
                <td className={classes.tableTitle}>Total</td>
                <td>{formatNumber(orderData.total)}</td>
              </tr>
              {paymentsMiniCard}
              <tr>
                <td className={classes.tableTitle}>Saldo</td>
                <td>
                  {orderStatus === 'remission'
                    ? formatNumber(balance)
                    : formatNumber(0)}
                </td>
              </tr>
            </tbody>
          </Table>
        </Paper>
      );
    return null;
  };

  const handlerenderPaymentsDetails = () => {
    let paymentsToRenderTemp = [];
    let paymentsMiniCardTemp = [];
    let balanceTemp = getNested(0, serviceOrderDetails, 'total');
    const tempPayments = getNested(
      getNested([], serviceOrderDetails, 'paymentOrders'),
      serviceOrderDetails,
      'advances',
    );
    if (tempPayments.length > 0) {
      tempPayments.forEach((payment, index) => {
        if (
          payment.status === 'pagado' ||
          payment.status === 'APPLIED'
        ) {
          balanceTemp -= payment.total;
          paymentsMiniCardTemp.push(
            <tr key={`minicard-${index}`}>
              <td className={classes.tableTitle}>
                Anticipo {index + 1}
              </td>
              <td>{formatNumber(payment.total)}</td>
            </tr>,
          );
        }
        paymentsToRenderTemp.push(
          <Link
            key={`payment-detail-link${payment.id}`}
            to={`/detail-advance/${payment.orderID}/${payment.agencyID}`}
          >
            <Paper
              key={`payment-${index}`}
              className={classes.paperCliente}
            >
              <DialogTitle className={classes.title}>
                Anticipo {index + 1}
              </DialogTitle>
              <Table bordered className={classes.tables}>
                <thead>
                  <tr>
                    <th>MONTO</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{formatNumber(payment.total)}</td>
                    <td>
                      {payment.status === 'pagado' ||
                        payment.status === 'APPLIED'
                        ? 'Pagado'
                        : 'Pendiente'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Paper>
          </Link>,
        );
      });
    }
    setBalance(balanceTemp);
    setPaymentsMiniCard(paymentsMiniCardTemp);
    setPaymentsToRender(paymentsToRenderTemp);
  };

  const renderUserPhones = (phones) => {
    if (Array.isArray(phones)) {
      return phones.map((phone, index) => (
        <tr key={`phone${index}`}>
          <td className={classes.tableTitle}>
            {`TELÉFONO ${index + 1}`}
          </td>
          <td>{phone || 'n/a'}</td>
        </tr>
      ));
    }
  };

  const renderUserDetails = () => {
    return (
      <Table bordered className={classes.tables}>
        <tbody>
          <tr>
            <td className={classes.tableTitle}>NOMBRE</td>
            <td>{orderData.userName}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>CORREO</td>
            <td>{orderData.userEmail}</td>
          </tr>
          {renderUserPhones(orderData.userPhoneNumber)}
          <tr>
            <td className={classes.tableTitle}>MODELO</td>
            <td>{orderData.model}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>MARCA</td>
            <td>{orderData.brand}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>PLACAS</td>
            <td>{orderData.plates}</td>
          </tr>
          <tr>
            <td className={classes.tableTitle}>VIN</td>
            <td>{orderData.vin}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderUrlDetails = () => {
    const landingUrl = handleUrlLanding();
    return (
      <>
        <ClickAwayListener onClickAway={handleHideTooltip}>
          <div>
            <MuiThemeProvider theme={theme}>
              <Tooltip
                className="copied-url-tooltip"
                placement="top"
                onClose={handleHideTooltip}
                open={showTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="¡URL copiada al portapapeles!"
              >
                <Table
                  bordered
                  className={classes.tables}
                  onClick={() => handleCopyText()}
                >
                  <tbody>
                    <tr>
                      <td className={classes.tableTitle}>URL</td>
                      <td>
                        {
                          orderData.urlPayment ? `${orderData.urlPayment.substring(0, 75)}...` : `${landingUrl}ods/${serviceOrderToken.substring(
                            0,
                            30,
                          )}...`
                        }
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Tooltip>
            </MuiThemeProvider>
          </div>
        </ClickAwayListener>
      </>
    );
  };

  return isEmpty(serviceOrderDetails) && isEmpty(orderData) ? (
    <SplashScreen />
  ) : (
    <div>
      {showPromotionsModal && renderPromotionsDialog()}
      {renderSendSmsDialog()}
      {renderSendWhatsDialog()}
      {renderSendMailDialog()}
      {renderReadyToPayDialog()}
      {showNotifyOrder && renderNotifyOrderDialog()}
      {showInvoiceOrder && renderInvoiceOrderDialog()}
      {renderFeedBackModal()}
      {renderFeedBackModalnotifications()}
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          {renderOrderDetails()}
          {renderAdvisorDetails()}
          {renderBalanceDetails()}
        </Grid>
        <Grid item xs={12} md={8} className={classes.rightContainer}>
          {orderData.status === 'Por pagar' && (
            <Paper className={classes.paperUrl}>
              <DialogTitle className={classes.title}>
                URL DE PAGO
              </DialogTitle>
              {renderUrlDetails()}
            </Paper>
          )}
          <Paper className={classes.paperCliente}>
            <DialogTitle className={classes.title}>
              Cliente
            </DialogTitle>
            {renderUserDetails()}
          </Paper>
          {getNested([], orderData, 'operations').length > 0 && (
            <Products
              title="Operaciones"
              products={orderData.operations}
              description="description"
              price="price"
            />
          )}
          {orderData &&
            orderData.detailPromotionByAgency &&
            !orderData.detailPromotionByAgency.isEighteen &&
            !orderData.detailPromotionByAgency.isNine &&
            !orderData.detailPromotionByAgency.isSix &&
            !orderData.detailPromotionByAgency.isSix &&
            !orderData.detailPromotionByAgency.isTwelve &&
            orderData.payments &&
            !orderData.payments.months &&
            orderData.payments.months > 0 && (
              <Alert variant="warning">
                Se deberá aplicar una nota de cargo.
              </Alert>
            )}
          {paymentsToRender}
          <Grid item>
            <RecentActivities
              logs={serviceOrdersLogs}
            ></RecentActivities>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  serviceOrders: state.serviceOrders,
  notifications: state.notifications,
  serviceOrdersLogs: makeSelectServiceOrdersLogs(state),
  serviceOrderDetails: makeSelectServiceOrderDetails(state),
  role: makeSelectUserRoleFromState(state),
  serviceOrderToken: makeSelectServiceOrderTokenFromState(state),
});

const mapDispatchToProps = {
  getOrderDetail,
  invoiceOrder,
  closeModals: closeModals,
  SendNotification: sendNotification,
  sendEmailLinkServiceOrder,
  updateServiceOrder,
  setReadyToPayServiceOrder,
  createOrUpdateSurtaxes,
  tokenizeServiceOrder,
  downloadfileXml,
};

export default withRouter(
  injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(DetailOrder),
  ),
);
