import 'react-table/react-table.css';

/* eslint-disable react/display-name */
import React, { useEffect, useState, useRef } from 'react';
import {
  Paper,
  makeStyles,
  IconButton,
  Button,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Badge } from 'react-bootstrap';

import Add from '@material-ui/icons/Add';
import GetApp from '@material-ui/icons/GetApp';
import Close from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import {
  formatDate,
  formatNumber,
  getBadgeColor,
  getColumnWidth,
  getNested,
  getPOStatusName,
} from '../../../../utils';
import {
  partsAsesorRoles,
  partsManagerRoles,
} from 'app/config/roles';
import { agenciesMercedes } from 'app/config/customizing';

import Modal from '../../../../components/Modal';
import MUIDatePickerFilter from 'app/components/MUIDatePickerFilter';
import { MenuOption } from 'app/components/MenuOption';

import { getAgency } from 'app/features/agencies/agenciesSlice';
import { getPaymentOrders } from '../../paymentOrdersSlice';
import { makeSelectAgencyDetailsFromState } from '../../../agencies/selectors';
import { makeSelectPaymentOrders } from '../../selectors';
import {
  makeSelectUserRoleFromState,
  makeCreationByAgency,
} from '../../../auth/selectors';

import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { injectIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(0),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(0),
  },
  datePickerTextfield: {
    padding: '5px 5px 5px !important',
    height: '20px',
  },
  range: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '30px',
  },
  datePicker: {
    ['@media screen and (max-width: 1496px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '70px',
    },
    ['@media screen and (max-width: 1024px)']: {
      // eslint-disable-line no-useless-computed-key
      width: '90px',
    },
    width: '90px',
  },
  icons: {
    color: '#0abb87',
    marginRight: '10px',
  },
  colorPrimary: {
    color: '#0abb87',
  },
  menuDesktop: {
    ['@media screen and (max-width: 1330px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  menuMobile: {
    ['@media screen and (min-width: 1330px)']: {
      // eslint-disable-line no-useless-computed-key
      display: 'none',
    },
  },
  formControl: {
    marginTop: '8px',
    marginRight: '5px',
    backgroundColor: '#FFF',
  },
}));

function PaymentOrdersTable(props) {
  const classes = useStyles();
  const {
    paymentOrdersFromdb,
    agencyDetail,
    role,
    createManuallyOdp,
    getAgency,
    setActionButton,
    fullOrders,
  } = props;
  const [orders, setOrders] = useState([]);
  const [ordersFiltered, setOrdersFiltered] = useState([]);
  const [tabSelected, setTabSelected] = useState(0);
  const reactTable = useRef(null);
  const [dataToCSV, setDataToCSV] = useState([]);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [sorted, setSorted] = useState([]);
  const [filtered, setFiltered] = useState('');
  const [nameFile, setNameFile] = useState('Ordenes-Pago.csv');
  const [showDateErrorModal, setShowDateErrorModal] = useState(false);
  const [closeDateErrorModal, setCloseDateErrorModal] =
    useState(false);
  const [disableDownloadCSV, setDisableDownloadCSV] = useState(false);

  useEffect(() => {
    const CSV = [];
    let searchIn;
    !Array.isArray(fullOrders)
      ? (searchIn = ordersFiltered)
      : (searchIn = orders);
    searchIn
      .filter((x) => x['isActiveAgency'] === true)
      .forEach((cursor) => {
        let type;
        switch (cursor.type) {
          case 'service':
            type = 'Servicio';
            break;
          case 'parts':
            type = 'Refacciones';
            break;
          case 'sales':
            type = 'Ventas';
            break;
          case 'fi':
            type = 'F&I y Garantías';
            break;
          case 'reservation':
            type = 'Reservación';
            break;
          case 'pulled_apart':
            type = 'Apartado';
            break;
          case 'hp':
            type = 'H&P';
            break;
          case 'maintenance':
            type = 'Mantenimiento';
            break;
          default:
            type = '-';
            break;
        }
        const data = {
          // prettier-ignore
          'ID de la orden': cursor.reference || '-',
          // prettier-ignore
          'Tipo': type || '-',
          // prettier-ignore
          'ID de Cliente': cursor.clientID || '-',
          // prettier-ignore
          'Agencia': cursor.agencyName || '-',
          // prettier-ignore
          'Creada por': cursor.createdBy || '-',
          // prettier-ignore
          'Nombre de cliente': cursor.name || '-',
          // prettier-ignore
          'Fecha registro en Karlo ': cursor.created || '-',
          // prettier-ignore
          'Fecha pago': cursor.date || '-',
          // prettier-ignore
          'Total': cursor.total || '-',
          // prettier-ignore
          'Estatus': cursor.status || '-',
        };
        CSV.push(data);
      });
    setDataToCSV(CSV);
    tabSelected === 0
      ? setNameFile('Ordenes-Pago.csv')
      : tabSelected === 1
        ? setNameFile('Ordenes-Pago-Canceladas.csv')
        : setNameFile('Ordenes-Pago-Pendientes-Facturar.csv');
  }, [orders, tabSelected, filtered]);

  // this is used for fill the table in the site delimiter for the row numbers
  useEffect(() => {
    if (Array.isArray(paymentOrdersFromdb)) {
      const cleanOrders = paymentOrdersFromdb.map((order) => {
        return {
          id: order.id ? order.id : 'n/a',
          agencyID: order.agencyID ? order.agencyID : 'n/a',
          isActiveAgency:
            order.agency && order.agency.isActiveAgency
              ? order.agency.isActiveAgency
              : false,
          agencyName: order?.agency?.name || 'n/a',
          reference: order.reference ? order.reference : 'n/a',
          clientID: getNested('-', order, 'clientID'),
          type: getNested('-', order, 'type'),
          name: order.nombre ? order.nombre : 'n/a',
          orderID: order.orderID ? order.orderID : 'n/a',
          nickname: order.agency ? order.agency.nickname : 'n/a',
          createdBy: order.createdBy ? order.createdBy : 'n/a',
          created: order.createdAt
            ? formatDate(order.createdAt)
            : 'n/a',
          date:
            order?.payments?.length > 0 &&
              order?.payments[order.payments.length - 1].status ===
              'DONE'
              ? formatDate(
                order.payments[order.payments.length - 1]?.date,
              )
              : 'n/a',
          total: order.amount
            ? formatNumber(order.amount)
            : order.total
              ? formatNumber(order.total)
              : 'n/a',
          status: order.status
            ? getPOStatusName(order.status)
            : 'n/a',
        };
      });
      setOrders(cleanOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, paymentOrdersFromdb]);

  // this is used for fill the csv file when exist the date filter or agency filter
  useEffect(() => {
    if (!Array.isArray(fullOrders) && fullOrders !== undefined) {
      const cleanOrders = fullOrders.rows.map((order) => {
        return {
          id: order.id ? order.id : 'n/a',
          agencyID: order.agencyID ? order.agencyID : 'n/a',
          isActiveAgency:
            order.agency && order.agency.isActiveAgency
              ? order.agency.isActiveAgency
              : false,
          agencyName: order?.agency?.name || 'n/a',
          reference: order.reference ? order.reference : 'n/a',
          clientID: getNested('-', order, 'clientID'),
          type: getNested('-', order, 'type'),
          name: order.nombre ? order.nombre : 'n/a',
          orderID: order.orderID ? order.orderID : 'n/a',
          nickname: order.agency ? order.agency.nickname : 'n/a',
          createdBy: order.createdBy ? order.createdBy : 'n/a',
          created: order.createdAt
            ? formatDate(order.createdAt)
            : 'n/a',
          date:
            order?.payments?.length > 0 &&
              order?.payments[order.payments.length - 1].status ===
              'DONE'
              ? formatDate(
                order.payments[order.payments.length - 1]?.date,
              )
              : 'n/a',
          total: order.amount
            ? formatNumber(order.amount)
            : order.total
              ? formatNumber(order.total)
              : 'n/a',
          status: order.status
            ? getPOStatusName(order.status)
            : 'n/a',
        };
      });
      setOrdersFiltered(cleanOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected, fullOrders]);

  useEffect(() => {
    if (firstDate && secondDate && firstDate > secondDate) {
      setShowDateErrorModal(true);
      setDisableDownloadCSV(true);
      renderFeedBackModal();
    } else {
      setDisableDownloadCSV(false);
    }
  }, [firstDate, secondDate]);

  useEffect(() => {
    if (role !== 'Super Admin') getAgency();
  }, [getAgency, role]);

  useEffect(() => {
    props.setLeftSideComponent(
      <div>
        <FormControl
          variant="outlined"
          size="small"
          lassName={classes.formControl}
        >
          <InputLabel htmlFor="name-native-disabled">
            Selecciona tipo de orden
          </InputLabel>
          <Select
            value={tabSelected}
            onChange={handleChangeTab}
            label="orderType"
          >
            <MenuItem value={0}>Abiertas</MenuItem>
            <MenuItem value={1}>Canceladas</MenuItem>
            <MenuItem value={2}>Pendientes por facturar</MenuItem>
          </Select>
        </FormControl>
        ,
      </div>,
    );
    reactTable.current && reactTable.current.fireFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    setActionButton(
      <Grid container justify="flex-start">
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
          <DatePicker
            id="mui-pickers-firstDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Inicial"
            format="DD/MM"
            className={classes.datePicker}
            value={firstDate}
            onChange={handleFirstDate}
            InputProps={{
              endAdornment: firstDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearFirstDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
          <span className={classes.range}>&nbsp;A&nbsp;</span>
          <DatePicker
            id="mui-pickers-secondDate"
            autoOk
            disableToolbar
            disableFuture
            variant="inline"
            label="Final"
            format="DD/MM"
            className={classes.datePicker}
            value={secondDate}
            onChange={handleSecondDate}
            InputProps={{
              endAdornment: secondDate ? (
                <IconButton
                  aria-label="Select locale"
                  size="small"
                  onClick={handleClearSecondDate}
                >
                  <Close />
                </IconButton>
              ) : null,
            }}
          />
        </MuiPickersUtilsProvider>
        <div className={classes.menuDesktop}>
          <CSVLink
            data={dataToCSV}
            filename={nameFile}
            onClick={() => {
              if (disableDownloadCSV) return false;
            }}
          >
            <Button
              variant="contained"
              className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10 kt-margin-r-10"
              disabled={disableDownloadCSV}
            >
              Exportar
              <GetApp className="kt-margin-l-10" />
            </Button>
          </CSVLink>
          {createManuallyOdp === true && (
            <Link to={'/add-paymentorder'}>
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Agregar orden de pago
                <Add className="kt-margin-l-10" />
              </Button>
            </Link>
          )}
        </div>
        <div className={classes.menuMobile}>
          <MenuOption
            Button={
              <Button
                variant="contained"
                className="btn btn-label-success btn-bold btn-sm btn-icon-h kt-margin-t-10"
              >
                Ver opciones
                <ArrowDropDownIcon className="kt-margin-l-10" />
              </Button>
            }
            options={[
              {
                name: (
                  <CSVLink
                    data={dataToCSV}
                    filename={nameFile}
                    onClick={() => {
                      if (disableDownloadCSV) return false;
                    }}
                  >
                    <Typography className={classes.colorPrimary}>
                      <GetApp className={classes.icons} />
                      Exportar
                    </Typography>
                  </CSVLink>
                ),
                onClick: genericFuntion,
              },
              {
                name: createManuallyOdp === true && (
                  <Link to={'/add-paymentorder'}>
                    <Typography className={classes.colorPrimary}>
                      <Add className={classes.icons} />
                      Agregar orden de pago
                    </Typography>
                  </Link>
                ),
                onClick: genericFuntion,
              },
            ]}
          />
        </div>
      </Grid>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agencyDetail,
    tabSelected,
    orders,
    nameFile,
    dataToCSV,
    disableDownloadCSV,
  ]);

  const handleFetchData = debounce(
    (state, instance) => {
      if (state) {
        const { page, pageSize, sorted, filtered } = state;
        props.getPaymentOrders({
          page,
          pageSize,
          sorted,
          filtered,
          canceled: tabSelected,
          firstDate,
          secondDate,
        });
        setPage(page);
        setPageSize(pageSize);
        setSorted(sorted);
        setFiltered(filtered);
      } else {
        props.getPaymentOrders({
          page,
          pageSize,
          sorted,
          filtered,
          canceled: tabSelected,
          firstDate,
          secondDate,
        });
      }
    },
    1000,
    { leading: false, trailing: true },
  );

  const handleChangeTab = (event, value) => {
    setTabSelected(event.target.value);
  };

  const handleCloseModals = () => {
    setCloseDateErrorModal(!closeDateErrorModal);
    setShowDateErrorModal(false);
  };

  const handleFirstDate = (date) => {
    setFirstDate(date);
  };

  const handleSecondDate = (date) => {
    setSecondDate(date);
  };

  useEffect(() => {
    handleFetchData();
  }, [firstDate, secondDate]);

  const handleClearFirstDate = () => {
    setFirstDate(null);
  };

  const handleClearSecondDate = () => {
    setSecondDate(null);
  };

  const genericFuntion = () => {
    // Don't delete this funtion, is called by the top bar menu
    // buttons that do not require the component's onClick function
  };

  const columns = [
    {
      Header: () => (
        <strong>
          {agencyDetail?.nickname === 'WORLDTRAVELANDFUN'
            ? 'Número de orden WTF'
            : 'ID de la orden'}
        </strong>
      ),
      accessor: 'reference',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Tipo</strong>,
      accessor: 'type',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value && row.value === 'insurance' && 'Seguro'}
            {row.value && row.value === 'service' && 'Servicio'}
            {row.value && row.value === 'parts' && 'Refacciones'}
            {row.value && row.value === 'sales' && 'Ventas'}
            {row.value && row.value === 'fi' && 'F&I y Garantías'}
            {row.value && row.value === 'hp' && 'H&P'}
            {row.value &&
              row.value === 'reservation' &&
              'Reservación'}
            {row.value && row.value === 'pulled_apart' && 'Apartado'}
            {row.value &&
              row.value === 'maintenance' &&
              'Mantenimiento'}
          </div>
        </Link>
      ),
    },
    {
      Header: () => (
        <strong>
          {agencyDetail?.nickname === 'WORLDTRAVELANDFUN'
            ? 'Número de reservación'
            : 'ID del cliente'}
        </strong>
      ),
      accessor: 'clientID',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value ? row.value : '-'}
          </div>
        </Link>
      ),
    },
    {
      Header: () => <strong>Agencia</strong>,
      accessor: 'agencyName',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show: role === 'Super Admin',
    },
    {
      Header: () => <strong>ID de Cliente</strong>,
      accessor: 'reference',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      show:
        agenciesMercedes.includes(
          getNested(null, agencyDetail, 'id'),
        ) &&
        (partsAsesorRoles.includes(role) ||
          partsManagerRoles.includes(role)),
    },
    {
      Header: () => <strong>Nombre de cliente</strong>,
      accessor: 'name',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      width: getColumnWidth(orders, 'name', 'Nombre de cliente'),
    },
    {
      Header: () => <strong>Fecha registro en Karlo</strong>,
      accessor: 'created',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: getColumnWidth(orders, 'created', 'Fecha creación'),
    },
    {
      Header: () => <strong>Fecha pago</strong>,
      accessor: 'date',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return <MUIDatePickerFilter cell={cell} />;
      },
      width: 140,
    },
    {
      Header: () => <strong>Total</strong>,
      accessor: 'total',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            {row.value}
          </div>
        </Link>
      ),
      filterable: false,
    },
    {
      Header: () => <strong>Estatus</strong>,
      accessor: 'status',
      Cell: (row) => (
        <Link
          to={`/detail-payment-order/${row.original.orderID}/${row.original.agencyID}/`}
        >
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <Badge
              className={classes.badges}
              variant={getBadgeColor(row.value)}
            >
              {row.value}
            </Badge>
          </div>
        </Link>
      ),
      Filter: (cell) => {
        return (
          // eslint-disable-next-line jsx-a11y/no-onchange
          <select
            disabled={tabSelected === 1 || tabSelected === 2}
            onChange={(e) => cell.onChange(e.target.value)}
            value={
              cell.filter && cell.filter.value
                ? cell.filter.value
                : ''
            }
          >
            <option value="">Todos</option>
            {tabSelected === 0 ? (
              <>
                <option value="por pagar">Por pagar</option>
                <option value="pagado">Pagada</option>
                <option value="facturada">Facturada</option>
              </>
            ) : null}
          </select>
        );
      },
    },
  ];

  const renderFeedBackModal = () => {
    try {
      const title = 'Error en las fechas';
      const desc =
        'Por favor revise que la fecha inicial y la fecha final no estén invertidas ya que de lo contrario la información podría no ser correcta ni podrá descargar los reportes';
      return (
        <Modal
          open={showDateErrorModal}
          type={'error'}
          closeModal={handleCloseModals}
          dialogTitle={title}
          dialogText={desc}
          actionButtonText="Aceptar"
          onClick={handleCloseModals}
        />
      );
    } catch (e) {
      handleCloseModals();
      return null;
    }
  };

  return (
    <div className={classes.root}>
      {renderFeedBackModal()}
      <Paper className={classes.paper}>
        <ReactTable
          ref={reactTable}
          NoDataComponent={() => (
            <div className="rt-noData">Sin resultados</div>
          )}
          manual
          onFetchData={handleFetchData}
          data={orders}
          pages={props.orders.tablePages}
          columns={columns}
          filterable
          className="-striped -highlight"
          previousText="Anterior"
          nextText="Siguiente"
          loading={props.orders.isLoading}
          loadingText="Cargando datos..."
        />
      </Paper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  orders: state.paymentOrders,
  fullOrders: state.paymentOrders.fullOrders,
  paymentsTPVFiserv: state.payments.paymentsTPVFiservfromdb,
  paymentOrdersFromdb: makeSelectPaymentOrders(state),
  agencyDetail: makeSelectAgencyDetailsFromState(state),
  role: makeSelectUserRoleFromState(state),
  createManuallyOdp: makeCreationByAgency(state),
});

const mapDispatchToProps = {
  getPaymentOrders: getPaymentOrders,
  getAgency: getAgency,
};

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(PaymentOrdersTable),
);
