import React, { useEffect, useState } from 'react';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/styles';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import {
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { getMainRoute, getNested } from './utils';
import { shallowEqual, useSelector } from 'react-redux';

import AuthPage from './features/auth/container/AuthPage';
import ErrorsPage from './pages/errors/ErrorsPage';
import { LayoutContextProvider } from '../_metronic';
import LogoutPage from './features/auth/components/Logout';
import RoutesControllers from './RoutesControllers';
// import { tawkToService } from './services/tawkToService';

const useStyles = makeStyles((theme) => ({
  contactButton: {
    color: 'white',
    width: '75px',
    height: '75px',
    background: '#25d366',
    position: 'fixed',
    bottom: '10px',
    right: '20px',
    zIndex: '999',
    ['@media screen and (max-width: 768px)']: {
      bottom: '85px!important',
    },
  },
}));

export const Routes = withRouter(({ Layout, history, location }) => {
  const classes = useStyles();
  const { isAuthorized, menuConfig, agencyID, dms, user } =
    useSelector(
      ({ auth, agencies, builder: { menuConfig } }) => ({
        menuConfig,
        isAuthorized: auth.user !== null,
        agencyID: getNested(null, auth, 'user', 'agencyID'),
        dms: getNested(null, agencies, 'agencyDetails', 'DMS'),
        user: auth.user,
      }),
      shallowEqual,
    );

  const [actionButton, setActionButton] = useState(null);
  const [leftSideComponent, setLeftSideComponent] = useState(null);

  const cleanSubheaderComponents = () => {
    setActionButton(null);
    setLeftSideComponent(null);
  };

  // useEffect(() => {
  // tawkToService(
  //   '5e1e11b627773e0d832d8a97/1duioo7qv',
  //   `${getNested('Visitante', user, 'name')} - Karlo`,
  //   getNested(null, user, 'email'),
  //   () => {},
  // );
  // }, [user]);

  useEffect(() => {
    cleanSubheaderComponents();
  }, [location.pathname]);

  const handleRoutes = () => {
    return Object.keys(RoutesControllers).map((route) => {
      const Component = RoutesControllers[route];
      // const routes = getRouteByRoles(role);
      // return Object.keys(routes).map(route => {
      //   const Component = routes[route];
      return (
        <Route
          exact
          key={route}
          path={route}
          render={(routeProps) => (
            <>
              <Layout
                actionButton={actionButton}
                leftSideComponent={leftSideComponent}
              >
                <Fab
                  className={classes.contactButton}
                  aria-label="add"
                >
                  <a
                    target="_blank"
                    style={{ color: 'white' }}
                    href={`https://wa.me/525610670424`}
                  >
                    <WhatsAppIcon style={{ fontSize: '50px' }} />
                  </a>
                </Fab>
                ;
                <Component
                  setActionButton={setActionButton}
                  actionButton={actionButton}
                  setLeftSideComponent={setLeftSideComponent}
                  leftSideComponent={leftSideComponent}
                  {...routeProps}
                ></Component>
              </Layout>
            </>
          )}
        />
      );
    });
  };

  const handleBeforeRedirect = () => {
    /* Render auth page when user at `/auth` and not authorized. */
    /* Otherwise redirect to root page (`/`) */
    if (
      !isAuthorized ||
      location.pathname.includes('auth/confirm-email')
    )
      return <Route path="/auth" component={AuthPage} />;
    else if (isAuthorized && !agencyID)
      return <Redirect from="/auth" to={getMainRoute(user?.role)} />;
    else if (isAuthorized && agencyID && dms)
      return <Redirect from="/auth" to={getMainRoute(user?.role)} />;
    else return <Route path="/auth" component={AuthPage} />;
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        {handleBeforeRedirect()}
        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ||
        location.pathname.includes('auth/confirm-email') ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth" />
        ) : (
          handleRoutes()
        )}
        <Redirect to="/error/error-v1" />
      </Switch>
    </LayoutContextProvider>
  );
});
